import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 549.000000 685.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,685.000000) scale(0.100000,-0.100000)">
          
          <path d="M3497 6420 c-27 -48 -28 -48 -89 -55 l-63 -8 43 -38 44 -38 -7 -56
c-7 -51 -6 -55 11 -49 11 4 24 10 29 14 43 28 63 28 115 4 29 -13 55 -22 58
-20 2 3 -1 27 -7 53 l-12 48 40 35 c23 19 41 38 41 42 0 3 -26 9 -59 13 -56 6
-59 8 -76 46 -29 62 -37 64 -68 9z"/>
<path d="M3758 6081 c-27 -48 -28 -48 -90 -56 l-63 -7 43 -37 c43 -37 43 -38
36 -89 -8 -59 -6 -60 59 -32 l46 21 52 -26 c28 -15 53 -24 55 -22 3 2 0 26 -6
53 l-11 49 40 35 c23 20 41 38 41 42 0 3 -26 9 -58 13 -58 7 -59 7 -83 56 -13
27 -26 49 -29 49 -3 0 -17 -22 -32 -49z"/>
<path d="M2883 6000 c-89 -20 -238 -76 -312 -118 -287 -159 -564 -462 -772
-842 l-44 -81 -65 8 c-122 15 -327 8 -424 -16 -179 -43 -347 -161 -439 -308
-52 -83 -70 -157 -71 -288 -1 -104 2 -128 23 -180 50 -123 128 -184 219 -170
86 13 134 53 177 147 29 64 46 211 27 244 -9 16 -11 13 -17 -21 -15 -82 -45
-115 -105 -115 -45 0 -67 15 -91 62 -30 59 -24 192 11 263 91 183 351 286 611
243 82 -13 80 5 20 -173 -144 -420 -221 -923 -221 -1445 0 -256 14 -445 60
-837 16 -141 35 -331 41 -422 6 -91 14 -199 17 -240 l7 -74 59 61 c68 71 170
144 274 196 39 20 72 42 72 50 0 7 -27 35 -61 62 -73 59 -146 147 -187 229
-71 143 -125 408 -141 708 -8 128 -13 164 -37 227 -22 61 -28 97 -32 192 -5
130 3 176 47 278 20 45 32 97 41 170 25 221 103 550 189 800 27 77 52 144 55
147 16 17 214 -102 331 -197 114 -93 291 -297 328 -377 9 -21 17 -23 84 -23
40 0 73 4 73 8 0 4 -24 44 -53 89 -181 272 -416 497 -637 608 -30 15 -56 29
-58 30 -2 2 21 52 51 111 30 60 58 127 61 150 14 83 97 196 193 260 32 21 91
69 131 107 40 37 110 95 155 129 85 63 257 153 314 164 l33 6 2 -447 3 -448
115 -38 c63 -21 116 -39 117 -39 0 0 3 201 6 448 3 246 10 470 15 499 11 56
32 106 69 165 13 21 23 42 23 48 0 17 -197 10 -287 -10z"/>
<path d="M3379 5916 c-26 -49 -28 -51 -104 -61 l-50 -7 44 -39 44 -39 -11 -53
c-6 -28 -9 -53 -7 -55 1 -2 27 9 57 24 l54 26 53 -27 c29 -14 55 -24 58 -22 2
3 0 22 -6 44 -16 58 -14 66 29 96 22 16 40 32 40 37 0 4 -27 11 -59 15 -52 6
-61 10 -70 33 -7 15 -20 37 -30 50 l-17 24 -25 -46z"/>
<path d="M4105 5886 c-79 -34 -157 -167 -163 -278 -2 -38 -1 -68 2 -68 3 0 22
18 41 39 46 51 75 58 130 31 33 -16 49 -33 67 -68 21 -42 23 -61 23 -182 0
-157 -16 -216 -90 -335 -79 -126 -180 -213 -505 -430 -323 -217 -385 -263
-475 -354 -33 -34 -63 -61 -67 -61 -4 0 -8 33 -8 74 l0 74 -112 40 c-62 22
-117 37 -121 33 -4 -4 -6 -146 -5 -314 2 -169 0 -307 -4 -307 -3 0 -11 13 -16
28 -8 25 -17 30 -57 35 -81 12 -98 9 -90 -10 27 -64 86 -240 100 -301 15 -60
23 -75 42 -83 31 -12 142 -12 179 0 16 5 41 12 56 15 26 6 27 8 30 99 4 73 14
125 50 247 81 274 86 282 228 405 43 36 199 135 214 135 20 0 128 -195 178
-320 35 -87 74 -250 93 -385 23 -162 55 -451 55 -491 0 -50 19 -43 91 34 71
75 127 170 145 248 11 47 9 62 -17 163 -61 235 -181 501 -324 715 l-84 126 97
49 c213 107 384 240 485 376 133 179 177 319 177 565 0 143 -13 209 -64 315
-39 80 -74 121 -122 141 -42 17 -119 17 -159 0z"/>
<path d="M3150 5495 c0 -37 -8 -33 105 -50 76 -11 162 -46 203 -81 67 -57 69
-134 4 -206 -35 -39 -35 -41 -17 -54 13 -9 47 -14 105 -14 72 0 94 4 138 26
76 38 112 83 112 141 0 156 -184 242 -572 267 l-78 4 0 -33z"/>
<path d="M2675 5493 c-389 -86 -579 -220 -593 -416 -8 -110 37 -196 153 -289
94 -77 210 -130 530 -243 165 -58 317 -112 338 -120 20 -8 44 -15 53 -15 8 0
45 24 82 53 37 29 97 73 135 97 37 25 67 47 67 51 0 3 -129 47 -287 98 -461
149 -634 218 -717 287 -134 112 -111 263 54 357 48 27 198 77 231 77 15 0 19
7 19 35 0 37 -9 41 -65 28z"/>
<path d="M3856 4407 c-18 -13 -17 -17 16 -68 69 -108 142 -247 194 -371 14
-34 36 -76 49 -92 67 -90 100 -198 100 -326 0 -278 -180 -511 -525 -681 -141
-69 -280 -111 -444 -132 l-91 -13 -3 -63 -3 -64 83 7 c214 18 495 97 682 192
315 159 497 351 563 594 32 118 20 322 -27 469 -64 203 -234 390 -471 519 -82
46 -96 49 -123 29z"/>
<path d="M2375 4039 c-258 -36 -484 -140 -641 -294 -92 -91 -128 -152 -151
-259 -48 -230 69 -469 312 -631 192 -128 496 -229 761 -251 79 -6 81 -6 87 17
4 13 7 41 7 61 0 41 -1 41 -115 53 -240 24 -505 113 -687 230 -235 152 -324
395 -224 611 50 107 184 220 346 289 166 71 322 98 534 93 l136 -3 0 48 0 47
-152 -1 c-84 -1 -180 -5 -213 -10z"/>
<path d="M3219 3858 c-17 -56 -19 -75 -10 -87 6 -9 23 -40 38 -70 49 -102 16
-212 -85 -285 -151 -110 -422 -108 -534 4 -44 44 -48 76 -21 159 9 28 14 53
12 55 -11 11 -120 -17 -169 -42 -78 -41 -114 -89 -113 -152 2 -113 110 -200
309 -245 114 -26 371 -27 474 -1 108 27 251 99 309 155 69 67 93 120 99 211 5
98 -20 169 -87 242 -46 50 -161 128 -188 128 -7 0 -22 -32 -34 -72z"/>
<path d="M2916 3071 l-88 -6 1 -124 c1 -69 4 -128 6 -132 3 -4 0 -67 -6 -140
-12 -146 -12 -148 -101 -246 -36 -40 -48 -76 -30 -87 5 -3 61 0 124 6 83 8
147 9 229 1 171 -15 179 -8 98 84 -94 106 -89 83 -89 386 l0 267 -27 -2 c-16
-1 -68 -4 -117 -7z"/>
<path d="M4135 2804 c-27 -19 -80 -51 -117 -72 -38 -20 -68 -41 -68 -47 0 -24
61 -247 95 -348 147 -440 353 -685 591 -705 82 -6 118 9 149 63 74 125 -27
300 -119 208 -25 -25 -27 -33 -24 -88 l3 -60 -38 2 c-77 5 -172 98 -235 233
-80 170 -124 361 -149 648 -10 123 -21 202 -28 201 -5 0 -32 -16 -60 -35z"/>
<path d="M2880 1486 c-73 -16 -173 -70 -212 -115 -85 -96 -106 -232 -52 -331
37 -69 80 -99 216 -152 78 -30 124 -55 157 -84 l46 -42 0 -74 c0 -66 -3 -79
-28 -111 -41 -54 -98 -80 -177 -80 -47 -1 -76 5 -106 20 -79 42 -145 140 -175
261 -11 44 -16 52 -33 50 -18 -3 -24 -23 -52 -173 -17 -93 -29 -173 -27 -177
2 -5 13 -8 24 -8 10 0 19 3 19 8 0 4 12 15 26 25 24 18 26 18 70 -3 166 -77
389 -70 516 17 93 65 138 131 159 237 10 48 9 66 -4 109 -32 100 -97 151 -283
221 -84 31 -148 83 -164 131 -41 124 78 250 216 229 93 -14 172 -111 197 -242
10 -54 14 -62 34 -62 13 0 23 2 23 4 0 2 13 70 30 152 16 82 30 155 30 162 0
18 -28 15 -61 -9 l-29 -21 -68 26 c-120 46 -193 54 -292 32z"/>
<path d="M3893 1489 c-174 -29 -340 -153 -427 -318 -96 -182 -104 -409 -20
-543 86 -137 251 -205 431 -178 104 16 167 41 250 99 147 104 234 254 263 458
35 239 -89 432 -306 478 -76 16 -113 17 -191 4z m174 -75 c75 -57 105 -185 84
-367 -37 -340 -167 -552 -336 -550 -87 0 -136 40 -168 134 -24 72 -30 156 -17
264 40 351 184 570 359 550 26 -3 58 -16 78 -31z"/>
<path d="M906 1413 c-131 -230 -475 -806 -494 -828 -30 -34 -51 -47 -105 -65
-31 -10 -53 -24 -55 -34 -3 -14 15 -16 182 -16 177 0 186 1 186 19 0 14 -9 21
-32 26 -51 11 -78 32 -78 62 0 22 56 145 93 204 11 17 27 19 184 19 l173 0 0
-27 c0 -15 8 -69 17 -120 20 -112 18 -115 -72 -135 -42 -9 -61 -19 -63 -31 -3
-16 14 -17 237 -17 235 0 241 0 241 20 0 15 -8 22 -29 26 -16 4 -41 19 -56 34
-28 30 -39 85 -133 685 l-37 240 -60 3 -60 3 -39 -68z m4 -338 c12 -77 24
-157 27 -177 l6 -38 -142 0 c-114 0 -142 3 -139 13 2 8 50 96 108 196 80 140
106 178 112 164 4 -10 17 -81 28 -158z"/>
<path d="M1540 1451 c0 -13 9 -21 30 -26 16 -3 41 -15 55 -26 31 -25 32 -51 6
-168 -11 -47 -31 -142 -46 -211 -74 -357 -94 -443 -109 -465 -16 -24 -69 -45
-113 -45 -16 0 -23 -6 -23 -20 0 -19 7 -20 240 -20 228 0 240 1 240 19 0 13
-12 22 -44 31 -75 21 -76 38 -21 285 8 33 17 77 21 98 5 32 10 37 34 37 22 0
32 -9 55 -52 15 -29 40 -75 55 -103 42 -77 105 -204 130 -263 l23 -52 166 2
c142 3 166 5 169 19 2 11 -9 19 -35 27 -68 20 -77 33 -295 424 -33 59 -58 113
-56 121 5 21 253 241 329 292 41 28 92 52 133 62 50 14 66 22 66 36 0 16 -13
17 -192 15 -168 -3 -193 -5 -196 -19 -2 -12 8 -18 38 -23 78 -12 65 -43 -79
-186 -53 -53 -136 -129 -186 -170 -49 -41 -91 -78 -93 -82 -2 -5 -12 -8 -23
-8 -24 0 -24 22 -3 110 8 36 24 110 35 165 27 136 41 156 127 170 32 5 42 11
42 26 0 18 -10 19 -240 19 -230 0 -240 -1 -240 -19z"/>
<path d="M4593 1454 c-6 -17 12 -34 38 -34 9 0 28 -9 43 -21 32 -25 33 -51 6
-168 -10 -47 -38 -174 -60 -281 -23 -107 -49 -234 -60 -283 -24 -118 -36 -133
-110 -149 -47 -9 -60 -16 -60 -30 0 -17 22 -18 415 -18 228 0 415 2 415 4 0 2
18 71 40 152 22 82 40 150 40 151 0 2 -6 3 -14 3 -8 0 -41 -37 -73 -82 -75
-107 -123 -154 -170 -167 -21 -6 -84 -11 -140 -11 -106 0 -124 6 -138 49 -3
10 13 106 35 212 23 107 57 268 75 359 46 219 51 236 80 258 13 11 47 22 75
26 41 5 50 10 50 26 0 19 -8 20 -240 20 -211 0 -241 -2 -247 -16z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
